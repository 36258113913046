<template>
	<div class="main-contents">
		<div class="mem_container resume">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">내 프로필 관리<br/><span>이력서 및 소속사 관리 / NCS 직무자가평가</span></div>
				</div>
				<div class="tab-type1">
					<ul class="tab">
						<li @click="pageMove('res')">
							<a><img src="/images/tec/prf/tab1.png" alt="이력서탭"/>이력서</a>
						</li>
						<li @click="pageMove('asgMng')">
                            <!-- <div v-if="asgRegYn == 'N'" class="alarm show"><img src="/images/tab_alarm.png" alt="미등록항목알람"><p>미등록 항목이 있습니다!</p></div> -->
                            <a><img src="/images/tec/prf/tab3.png" alt="소속사관리탭"/>소속사 관리</a>                            
                        </li>
						<li class="on" style="width: 381px; margin-right: 3px;">
							<a><img src="/images/tec/prf/tab2.png" alt="직무자가평가탭"/>NCS 직무 자가평가</a>
						</li>
					</ul>
				</div>
				<div class="duty_bg" ref="addNcs">
					<div class="notice">
						NCS 직무 자가진단 평가는 등록된 이력서의 기술등급(학력기준과 자격증기준)을 기준으로 진행됩니다.<br/>
						<span class="name">{{techData.mberNm}} </span>님 이력서의 기술등급(학력기준, 자격증기준)은 다음과 같습니다.
					</div>
					<div class="ranking-box">
						<div class="edu">
							<!-- <img :src="'/images/tec/prf/ranking_' + techData.schoolTechGrade + '.png'" alt="등급이미지"/> -->
							<gradeImg type="SCHOOL_TECH_NCS" :grade="techData.schoolTechGrade" />
						</div>
						<div class="qual">
							<!-- <img :src="'/images/tec/prf/ranking_' + techData.licenseTechGrade + '.png'" alt="등급이미지"/> -->
							<gradeImg type="LICENSE_TECH_NCS" :grade="techData.licenseTechGrade" />
						</div>
					</div>
					
					<div class="my-ncs after">
						<div class="my-ncs-tit after">
							NCS 직무 자가진단 평가
							<span class="ps">※ NCS 직무 자가진단 평가를 여러개 진행할 경우, 직무분야를 선택한 후 추가버튼을 클릭해 주세요.</span>
						</div>
						<div class="ncs-section after">
							<div class="ncs-clas">
								<div class="clas-tit">대분류</div>
								<div class="clas-select">
									<SelectComp v-model="majorClsfCd" :list="majorCdList" class="clas" codeKey="majorClassCd" nameKey="majorClassNm" title="선택하세요" ref="majorClassCd"/>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">중분류</div>
								<div class="clas-select">
									<SelectComp v-model="midClsfCd" :list="midCdList" class="clas" codeKey="midClassCd" nameKey="midClassNm" title="선택하세요" ref="midClassCd"/>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">소분류</div>
								<div class="clas-select">
									<SelectComp v-model="minorClsfCd" :list="minorCdList" class="clas" codeKey="minorClassCd" nameKey="minorClassNm" title="선택하세요" ref="minorClassCd"/>
								</div>
							</div>
							<div class="ncs-clas">
								<div class="clas-tit">직무분야</div>
								<div class="clas-select">
									<SelectComp v-model="detailClsfCd" :list="detailCdList" class="clas" codeKey="detailClassCd" nameKey="detailClassNm" title="선택하세요" ref="detailClassCd"/>
								</div>
							</div>
						</div>
						<div class="ncs-add_btn">
							<img src="/images/tec/prf/diagnosis_btn.png" alt="자가진단추가버튼" @click="updateNcsSelfEval('create')"/>
						</div>
					</div>
				</div>
				<div class="contents-body tab">
					<div class="duty-box notice" style="margin-bottom: 30px;">
						<div class="intro">
							<span class="ico_info red">!</span>
							기술등급(학력기준), 기술등급(자격증기준)중에서 높은 등급을 기준으로 NCS 직무 평가가 진행됩니다.<br/>
							<span class="ico_info">!</span>
							<span class="ex"> 예 : 기술등급(학력기준) : 초급 기술등급(자격증기준) : 고급 = 기술등급(자격증기준) 고급 기준으로 자가진단이 진행</span>
						</div>
					</div>
					<div class="duty-box notice">
						<div class="intro">
							<!-- <span class="ico_info red">!</span>
							NCS 직무 자가진단 평가는 최대 3가지의 직무분야만 가능합니다.<br/><br/> -->
							<span class="ico_info red">!</span>
							NCS Level 항목에 표시된 Level은 본인이 등록한 이력서에 의해 자동으로 산출된 Level이므로,<br/>
							<span class="ico_info"></span>
							각 Level에 따른 능력단위명에 해당하는 자가진단을 하여야 합니다.<br/>
							<span class="ico_info"></span>
							모든 능력단위명을 진단완료하여야 최종적으로 본인의 기술등급이 등록됩니다.<br/><br/>
							<span class="ico_info red">!</span>
							NCS 직무 자가평가 등록은 하단의 최종등록을 하기 전까지는 능력단위별 진단지 수정을 할 수 있습니다.<br/><br/>
							<span class="ico_info red">!</span>
							NCS Level : 초급 (L2~L4), 중급 (L5~L6), 고급 (L7), 특급 (L8)
						</div>
					</div>

					<!-- 자가진단 결과 -->
					<div class="duty-box result" v-for="(dutySelfEvalInfo, dutyIdx) in dutySelfEvalList" :key="dutyIdx">
						<div class="ranking_img" style="margin-bottom: 0;" v-if="dutySelfEvalInfo.ncsLevelCd"><img :src="'/images/tec/prf/ncs_' + dutySelfEvalInfo.imageNcsLevelCd + dutySelfEvalInfo.imageNcsGradeCd + '.png'" alt="등급"/></div>
						<div class="tit result" v-if="dutySelfEvalInfo.ncsGradeCd">NCS 직무 자가진단 평가결과</div>
						<div class="intro write mt" :ref="dutySelfEvalInfo.detailClassCd">
							[{{dutySelfEvalInfo.detailClassNm}}<span v-if="dutySelfEvalInfo.ncsLevelCd">/ {{dutySelfEvalInfo.ncsLevelCd + dutySelfEvalInfo.ncsGradeCd}}</span>]
							<img src="/images/tec/prf/cancel_circle_btn.png" alt="삭제" v-if="(techData.techGradeVerSeq == dutySelfEvalInfo.techGradeVerSeq && techData.resumeGradeAdmApprYn != 'Y') || dutySelfEvalInfo.evalFinishYn == 'N'" @click="updateNcsSelfEval('delete', dutySelfEvalInfo)"/>
							<img :src="'/images/tec/prf/' + dutySelfEvalInfo.show + '_bt.png'" :alt="dutySelfEvalInfo.show == 'show' ? '내리기' : '올리기'" @click="dutyShowCng(dutySelfEvalInfo.detailClassCd)"/>
						</div>
						<hr/>
						<div class="rq_btn" v-if="dutySelfEvalInfo.ncsLevelCd" @click="updateNcsSelfEval('reCreate', dutySelfEvalInfo)">재평가 &#9654;</div>
						<div class="Board type3" v-if="dutySelfEvalInfo.show == 'hide'">
							<ncs-job-table :ncsJobObj="dutySelfEvalInfo" @move="evalPageMove" @down="ncsDownload" />
						</div>
						<div class="division" v-if="dutySelfEvalList.length != dutyIdx + 1"></div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

import gradeImg from '@/components/highpro/GradeImg.vue';
import ncsJobTable from '@/components/highpro/ncsEval/ncsJobTable.vue';

export default {
	data() {
		return {
			// NCS 분류
			majorClsfCd : '',
			midClsfCd : '',
			minorClsfCd : '',
			detailClsfCd : '',

			// NCS 분류코드
			majorCdList : [],
			midCdList : [],
			minorCdList : [],
			detailCdList : [],

			// NCS 자가진단 평가 목록
			ncsSelfEvalList : [],

			// 기본정보
			techData : {},
			
			// NCS 자가평가 목록
			dutySelfEvalList : [],

			backwardDetailCd : this.$route.params.detailClassCd,
			backViewNm : this.$route.params.backViewNm || '',

			// 소속사 등록 확인
			// asgRegYn : '',

		}
	},

	components : {
		gradeImg, ncsJobTable
	},

	beforeMount() {
		// 소속사 등록 확인
		// this.asgRegChk();

		// 초기데이터 조회
		this.selectInitData();
		
		// NCS 대분류 코드 조회
		this.selectNcsClsfCd('major');

		if(this.$route.params.type == 'add'){
			this.$nextTick(() => {
				this.$.focus(this.$refs.addNcs);
			})
		}
	},

	watch : {
		majorClsfCd : function() { 
			this.midClsfCd = '';
			this.selectNcsClsfCd('mid');
		},
		midClsfCd : function() { 
			this.minorClsfCd = '';
			this.selectNcsClsfCd('minor');
		},
		minorClsfCd : function() { 
			this.detailClsfCd = '';
			this.selectNcsClsfCd('detail');
		},
	},

	methods : {
		// 소속사 등록 확인
		// asgRegChk(){
        //     this.$.httpPost('/api/mem/prf/getMberAsgRegInfo', {mberSeq : this.$store.state.userInfo.mberSeq})
        //         .then(res => {
        //             this.asgRegYn = res.data.mberAsgRegInfo.mberAsgRegYn;
        //         })
        //         .catch(err => {
        //             alert(err.response.data.error_description);
        //         });
        // },

		// NCS 분류 변경시 
		selectNcsClsfCd(div) {
			var param = {};
			param.div = div;
			param.techGrade = this.techData.techGrade;

			if(div == 'detail') {
				param.upCode = this.minorClsfCd
			}else if(div == 'minor') {
				param.upCode = this.midClsfCd
			}else if(div == 'mid') {
				param.upCode = this.majorClsfCd
			}

			if(div == 'major' || param.upCode) {
				this.$.httpPost('api/mem/prf/selectNcsClsfCd', param, '')
				.then(res => {

					// console.log('RESULT :', res);

					if(div == 'detail') {
						this.detailCdList = res.data.ncsClsfCd;
					}else if(div == 'minor') {
						this.minorCdList = res.data.ncsClsfCd;
					}else if(div == 'mid') {
						this.midCdList = res.data.ncsClsfCd;
					}else {
						this.majorCdList = res.data.ncsClsfCd;
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				})
			}else {
				if(div == 'detail') {
					this.detailCdList = [];
				}else if(div == 'minor') {
					this.minorCdList = [];
				}else if(div == 'mid') {
					this.midCdList = [];
				}else {
					this.majorCdList = [];
				}
			}
		},

		// 기본정보 조회
		selectInitData() {
			this.$.httpPost('api/mem/prf/selectNcsSelfEvalList', '', '')
				.then(res => {
					// console.log('selectInitData RESULT :', res);

					if(res.data.techData) {
						this.techData = res.data.techData;

						if(res.data.dutySelfEvalList) {
							this.dutySelfEvalList = res.data.dutySelfEvalList;

							for(var i in this.dutySelfEvalList) {
								if(this.backwardDetailCd && this.backwardDetailCd == this.dutySelfEvalList[i].detailClassCd) {
									this.dutySelfEvalList[i].show = 'hide';
									
									// 해당 직무 focus
									this.$nextTick(()=>{
										this.$.focus(this.$refs[this.backwardDetailCd])
									});

									if(this.backViewNm == 'PRF102M01') {
										var dtEndCnt = 0;
										
										for(var x in this.dutySelfEvalList[i].unitList) {
											if(this.dutySelfEvalList[i].unitList[x].chkProg == 'do') dtEndCnt++;
										}
										
										if(dtEndCnt == 0) this.popupOpen();
									}
								}else if(!this.backwardDetailCd && i == 0) {
									this.dutySelfEvalList[i].show = 'hide';
								}else {
									this.dutySelfEvalList[i].show = 'show';
								}
							}
						}
					}else {
						// alert('이력서를 먼저 등록해야 합니다.');
						// 	this.$router.push('PRF101M01');	

						this.$.popup('/tec/prf/PRF101P03').then(() => {
							this.$router.push('PRF101M01');	
						}).catch(() => {
							// console.log(err);
						});
						
					}
				})
				.catch(() => {
					// console.log(err);
				})
		},

		// NCS 자가진단 직무 추가/삭제
		updateNcsSelfEval(div, info) {
			if(div == 'create') {
				if(this.detailClsfCd == '') {
					alert('직무분야를 선택해주세요.');
					return;
				}

				for(var i in this.dutySelfEvalList) {
					if(this.dutySelfEvalList[i].detailClassCd == this.detailClsfCd) {
						alert("선택하신 직무분야는 이미 추가 되어 있습니다.");
						return;
					}
				}
			}

			var param = {};
			param.techGradeVerSeq = this.techData.techGradeVerSeq;
			param.detailClsfCd = this.detailClsfCd;
			if(div == 'reCreate') param.detailClsfCd = info.detailClassCd;
			
			if(div == 'delete') {
				param.delYn = 'Y';
				param.mberTechGradeFinalSeq = info.mberTechGradeFinalSeq

				if(!confirm('해당 직무를 삭제하시겠습니까?')) {
					return false;
				}
			}

			this.$.httpPost('api/mem/prf/updateNcsSelfEval', param, '')
				.then(() => {
					// console.log(res);

					this.backwardDetailCd = this.detailClsfCd;
					this.selectInitData();
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				})
		},

		// NCS 직무 숨김처리
		dutyShowCng(dtCd) {
			for(var i in this.dutySelfEvalList) {
				if(this.dutySelfEvalList[i].detailClassCd == dtCd) {
					if(this.dutySelfEvalList[i].show == 'show') this.dutySelfEvalList[i].show = 'hide';
					else this.dutySelfEvalList[i].show = 'show';
					this.$forceUpdate();
				}
			}
		},

		// NCS 자가진단 평가하기/평가보기
		evalPageMove(info, unitIdx) {
			var param = {};
			param.techGradeVerSeq = this.techData.techGradeVerSeq;
			param.mberTechGradeFinalSeq = info.mberTechGradeFinalSeq;
			param.abilUnitCd = info.unitList[unitIdx].abilUnitCd;
			param.techGrade = this.techData.techGrade;

			if(!info.ncsLevelCd) {
				this.$router.push({name:'PRF102M01', params:{secrets : param, caller: {name:'PRF102M02', params:{detailClassCd:info.detailClassCd, backViewNm:'PRF102M01'}}}});
			}else {
				this.$.popup('/tec/prf/PRF102P01', param, '', '');
			}
		},

		// 페이지 이동
		pageMove(div) {
            if(div == 'res') {
                this.$router.push('PRF101M01');
            } else if(div == 'asgMng') { 
                this.$router.push('PRF104M01');
            } else {
				this.$router.go(-1);
			}    
		},
		
		// NCS 직무기술서 다운로드
		ncsDownload(filename) {
			this.$.fileDownload('/files/tech/prf/ncs/' + filename + '.pdf', '', '', 'GET');
		},

		popupOpen() {
			this.$.popup('/tec/prf/PRF102P02');
		}
		
	}
}
</script>